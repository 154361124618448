/* eslint-disable @typescript-eslint/no-explicit-any */

import { rest } from 'msw'

import Env from 'config/Env'
// import { Post } from 'features/videos'
import { db } from 'test/msw/db'

const BASE_URL = `${Env.API_BASE_URL}/video/videos`

export const videosHandlers = [
  rest.get(BASE_URL, (req, res, ctx) => {
    try {
      const result = db.videos.getAll()
      return res(ctx.json(result))
    } catch (error: any) {
      return res(ctx.status(400), ctx.json({ message: error?.message || 'Server Error' }))
    }
  }),
]
