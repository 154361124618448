/* eslint-disable @typescript-eslint/no-explicit-any */
import { Env } from 'config/Env'
import makeApi from 'libs/core/configureAxios'

const api = makeApi(`${Env.API_BASE_URL}`)

const EXERCISES_BASE_URL = `practice/practices`

export const getExercises = (): Promise<[]> => api.get(EXERCISES_BASE_URL)
export const registerAttempt = (
  exercise_id: string,
  answer: string,
  seconds_to_response: number,
): Promise<[]> => {
  console.log({ answer, seconds_to_response })
  return api.post(`${EXERCISES_BASE_URL}/${exercise_id}/attempts`, { answer, seconds_to_response })
}
