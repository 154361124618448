import { videosHandlers } from 'test/msw/handlers/videos'

import { postsHandlers } from './posts'

// import { postsHandlers } from 'test/msw/handlers/posts'

export const handlers = [
  // ...db.posts.toHandlers('rest'),
  ...postsHandlers,
  ...videosHandlers,
]
