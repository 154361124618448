import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import AuthLayout from 'components/AuthLayout'

const HomePage = React.lazy(() => import('pages/HomePage'))
const AboutPage = React.lazy(() => import('pages/AboutPage'))
const PostPage = React.lazy(() => import('pages/PostPage'))
const VideoPage = React.lazy(() => import('pages/VideoPage'))
const LandingPage = React.lazy(() => import('pages/LandingPage'))
const ExercisePage = React.lazy(() => import('pages/ExercisePage'))

const AppRoutes = () => (
  <>
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route element={<AuthLayout />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/posts" element={<PostPage />} />
          <Route path="/videos" element={<VideoPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/exercises" element={<ExercisePage />} />
        </Route>
      </Routes>
    </Suspense>
  </>
)

export default AppRoutes
