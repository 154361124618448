import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { Button, ButtonGroup, IconButton, Link } from '@mui/material'
import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { NavDrawerProps } from 'components/NavDrawer/NavDrawer'

const NavBar = (props: NavDrawerProps) => {
  const { currentThemeMode, onChangeThemeClick, onChangeLanguage } = props
  return (
    <nav>
      {props.routes.map(route => (
        <Link
          key={`${route.link}${route.name}`}
          component={RouterLink}
          to={route.link}
          variant="button"
          color="text.primary"
          sx={{ my: 1, mx: 1.5 }}
        >
          {route.name}
        </Link>
      ))}

      <ButtonGroup variant="text" color="inherit">
        <Button onClick={() => onChangeLanguage('en')}>🇺🇸</Button>
        <Button onClick={() => onChangeLanguage('pl')}>🇵🇱</Button>
      </ButtonGroup>
      <IconButton sx={{ ml: 1 }} onClick={onChangeThemeClick} color="inherit">
        {currentThemeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </nav>
  )
}

export default NavBar
