import { useTheme, useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import NavBar from 'components/NavBar/NavBar'
import NavDrawer from 'components/NavDrawer/NavDrawer'
import { appBarHeight } from 'constants/styling'

export type HeaderProps = {
  currentThemeMode: 'light' | 'dark'
  onChangeThemeClick: () => void
  onChangeLanguage: (lang: string) => void
}

const Header = ({ currentThemeMode, onChangeThemeClick, onChangeLanguage }: HeaderProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const routes = [
    { name: t('navigation.links.home'), link: '/home' },
    {
      name: t('navigation.links.videos'),
      link: '/videos',
    },
    { name: t('navigation.links.exercises'), link: '/exercises' },
    // { name: t('navigation.links.posts'), link: '/posts' },
    { name: t('navigation.links.about'), link: '/about' },
  ]

  return (
    <>
      <AppBar
        color="primary"
        elevation={0}
        position="fixed"
        sx={{
          height: appBarHeight,
          top: '0',
          bottom: 'auto',
          borderBottom: () => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Kodelingo
          </Typography>
          {matches ? (
            <NavDrawer
              currentThemeMode={currentThemeMode}
              onChangeThemeClick={onChangeThemeClick}
              onChangeLanguage={onChangeLanguage}
              routes={routes}
            />
          ) : (
            <NavBar
              currentThemeMode={currentThemeMode}
              onChangeThemeClick={onChangeThemeClick}
              onChangeLanguage={onChangeLanguage}
              routes={routes}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
