/* eslint-disable @typescript-eslint/no-explicit-any */
import { Env } from 'config/Env'
// import { VideoListType } from 'features/videos/types'
import makeApi from 'libs/core/configureAxios'

const api = makeApi(`${Env.API_BASE_URL}`)

const VIDEOS_BASE_URL = `/video/videos`

export const getVideos = (): Promise<[]> => api.get(VIDEOS_BASE_URL)
export const getVideoLinks = (videoHref: string): Promise<[]> => api.get(`/${videoHref}`)
