import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery, select } from 'redux-saga/effects'

import { getVideos, getVideoLinks } from 'features/videos/api'
import {
  videosActions,
  VideoPayload,
  SelectedVideoLinksPayload,
  selectSelectedVideo,
} from 'features/videos/store/videos.slice'
import { Video } from 'features/videos/types'

// Worker Sagas
export function* onGetVideo(): SagaIterator {
  const videosPayload: VideoPayload = yield call(getVideos)
  yield put(videosActions.fetchAllSucceeded(videosPayload))
}

export function* onGetVideoLinks(): SagaIterator {
  try {
    yield put(videosActions.setIsLoading(true))
    const selectedVideo: Video = yield select(selectSelectedVideo)
    if (selectedVideo) {
      const videosPayload: SelectedVideoLinksPayload = yield call(
        getVideoLinks,
        selectedVideo?._links?.self?.href as string,
      )
      if (videosPayload) {
        yield put(
          videosActions.setSelectedVideoLinks({
            transcript: videosPayload?._links.transcript.href,
            translations: videosPayload?._links.translations.href,
            thumbnail_url: videosPayload?.thumbnail_url,
            content_url: videosPayload?.content_urls?.large,
          }),
        )
      }
    } else {
      yield put(
        videosActions.setSelectedVideoLinks({
          transcript: '',
          translations: '',
          thumbnail_url: '',
          content_url: '',
        }),
      )
    }
    yield put(videosActions.setError(''))
    yield put(videosActions.setIsLoading(false))
  } catch (error) {
    yield put(videosActions.setIsLoading(false))

    const tempError = error as string
    yield put(videosActions.setError(tempError))
  }
}

export function* onSetSelectedVideo(): SagaIterator {
  yield put(videosActions.fetchVideoLinks())
}

// Watcher Saga
export function* videosWatcherSaga(): SagaIterator {
  yield takeEvery(videosActions.fetchAll.type, onGetVideo)
  yield takeEvery(videosActions.fetchVideoLinks.type, onGetVideoLinks)
  yield takeEvery(videosActions.setSelectedVideo.type, onSetSelectedVideo)
}

export default videosWatcherSaga
