// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Video } from 'features/videos/types'
import type { RootState } from 'store/store'

export interface SelectedVideoLinkState {
  transcript: string
  translations: string
  thumbnail_url: string
  content_url: string
}

export interface SelectedVideoLinksPayload {
  transcript: string
  translations: string
  thumbnail_url: string
  content_urls: { large: string }
  title: string
  watched: boolean
  language: string
  created_at: string
  _links: {
    translations: {
      href: string
    }
    transcript: {
      href: string
    }
  }
}

export interface VideosState {
  videos: Video[]
  selectedVideo: null | Video
  selectedVideoLinks: SelectedVideoLinkState
  isLoading: boolean
  error: string
}

export interface VideoPayload {
  items: Video[]
  _links: {
    self: { href: string }
  }
}

const initialState: VideosState = {
  videos: [],
  selectedVideo: null,
  selectedVideoLinks: {
    transcript: '',
    translations: '',
    thumbnail_url: '',
    content_url: '',
  },
  isLoading: false,
  error: '',
}

// slice
export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    fetchAllSucceeded(state, action: PayloadAction<VideoPayload>) {
      // it's okay to do this here, because immer makes it immutable under the hood😊
      state.videos = action.payload.items
    },
    setSelectedVideo(state, action: PayloadAction<Video | null>) {
      // it's okay to do this here, because immer makes it immutable under the hood😊
      state.selectedVideo = action.payload
    },
    setSelectedVideoLinks(state, action: PayloadAction<SelectedVideoLinkState>) {
      state.selectedVideoLinks = action.payload
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

// Actions
export const videosActions = {
  fetchVideoLinks: createAction(`${videosSlice.name}/fetchVideoLinks`),
  fetchAll: createAction(`${videosSlice.name}/fetchAll`),
  fetchAllSucceeded: videosSlice.actions.fetchAllSucceeded,
  setSelectedVideo: videosSlice.actions.setSelectedVideo,
  setIsLoading: videosSlice.actions.setIsLoading,
  setError: videosSlice.actions.setError,
  setSelectedVideoLinks: videosSlice.actions.setSelectedVideoLinks,
}

// Selectors
export const selectVideos = (state: RootState) => state.videos.videos
export const selectSelectedVideo = (state: RootState) => state.videos.selectedVideo
export const selectSelectedVideoLinks = (state: RootState) => state.videos.selectedVideoLinks
export const selectIsLoading = (state: RootState) => state.videos.isLoading
export const selectError = (state: RootState) => state.videos.error

// Reducer
export default videosSlice.reducer
