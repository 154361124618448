import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Exercise } from 'features/exercises/types'
import type { RootState } from 'store/store'

export interface ExercisesState {
  exercises: Exercise[]
}

export interface ExercisesPayload {
  items: Exercise[]
  _links: {
    self: { href: string }
  }
}

const initialState: ExercisesState = {
  exercises: [
    {
      _links: {
        self: {
          href: '/self/this',
        },
        create_attempt: {
          href: '/exercises/12345/attempts',
          method: 'POST',
        },
      },
      audio_url: 'https://my-audio.mp3',
      text: "It's my [], you can't have it!",
      exercise_type: 'audio_text_gap',
      solution: 'world',
      id: '9999',
    },
  ],
}

// slice
export const exercisesSlice = createSlice({
  name: 'exercises',
  initialState,
  reducers: {
    fetchAllSucceeded(state, action: PayloadAction<ExercisesPayload>) {
      // it's okay to do this here, because immer makes it immutable under the hood😊
      state.exercises = action.payload.items
    },
  },
})

// Actions
export const exercisesActions = {
  fetchAll: createAction(`${exercisesSlice.name}/fetchAll`),
  fetchAllSucceeded: exercisesSlice.actions.fetchAllSucceeded,
}

// Selectors
export const selectExercises = (state: RootState) => state.exercises.exercises

// Reducer
export default exercisesSlice.reducer
