import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getExercises } from 'features/exercises/api'
import { exercisesActions, ExercisesPayload } from 'features/exercises/store/exercises.slice'

// Worker Sagas
export function* onGetExercises(): SagaIterator {
  const exercisesPayload: ExercisesPayload = yield call(getExercises)
  yield put(exercisesActions.fetchAllSucceeded(exercisesPayload))
}

// Watcher Saga
export function* exercisessWatcherSaga(): SagaIterator {
  yield takeEvery(exercisesActions.fetchAll.type, onGetExercises)
}

export default exercisessWatcherSaga
