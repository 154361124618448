import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Menu from '@mui/icons-material/Menu'
import {
  Button,
  ButtonGroup,
  List,
  ListItem,
  IconButton,
  SwipeableDrawer,
  Link,
} from '@mui/material'
import React, { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

export type NavDrawerProps = {
  currentThemeMode: 'light' | 'dark'
  onChangeThemeClick: () => void
  onChangeLanguage: (lang: string) => void
  routes: { name: string; link: string }[]
}

const NavDrawer = (props: NavDrawerProps) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const [openDrawer, setDrawer] = useState(false)
  const { currentThemeMode, onChangeThemeClick, onChangeLanguage } = props

  return (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
      >
        <div />
        <List disablePadding>
          {props.routes.map(route => (
            <ListItem
              key={`${route.link}${route.name}`}
              divider
              //   selected={value === route.activeIndex}
              onClick={() => {
                setDrawer(false)
                // setValue(route.activeIndex)
              }}
            >
              <Link
                component={RouterLink}
                to={route.link}
                variant="button"
                color="text.primary"
                sx={{ my: 1, mx: 1.5 }}
              >
                {route.name}
              </Link>
            </ListItem>
          ))}

          <ButtonGroup variant="text" color="inherit">
            <Button onClick={() => onChangeLanguage('en')}>🇺🇸</Button>
            <Button onClick={() => onChangeLanguage('pl')}>🇵🇱</Button>
          </ButtonGroup>
          <IconButton sx={{ ml: 1 }} onClick={onChangeThemeClick} color="inherit">
            {currentThemeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </List>
      </SwipeableDrawer>
      <IconButton onClick={() => setDrawer(!openDrawer)} disableRipple>
        <Menu />
      </IconButton>
    </React.Fragment>
  )
}

export default NavDrawer
